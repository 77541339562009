@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    /* START: Defaults from shadcn */
    --background: 240 9.7% 6.1%;
    --foreground: 0 0% 98%;

    --card: 240 9.7% 6.1%;
    --card-foreground: 0 0% 95.7%;
 
    --popover: 240 9.7% 6.1%;
    --popover-foreground: 0 0% 95.7%;
 
    --primary: 287.6 70.1% 63.3%;
    --primary-foreground: 0 0% 98%;
 
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
 
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
 
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 95.7%;
 
    --destructive: 341.7 75.8% 35.7%;
    --destructive-foreground: 0 0% 98%;

    --border: 240, 3.7%, 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
 
    --radius: 0.5rem;
    /* END: Defaults from shadcn */

    /* Palette */
    --blueberry: 223.9 98% 59.8%;
    --cream: #D8D5D1;
    --cream-500: #d8d4d0;
    --raspberry: #DD265D;
    --raspberry-50: #FEDDE7;
    --raspberry-100: #FAA9C1;
    --raspberry-200: #F46B94;
    --raspberry-300: #ED497A;
    --raspberry-400: #DD265D;
    --raspberry-500: #CD164D;
    --raspberry-600: #B50E40;
    --raspberry-700: #93002C;
    --charcoal: #333230;
    --charcoal-700: #1C1C1C;
    --grape: #DF7EF8;
    --grape-500: #C860E3;
    --grape-600: #BA66DC;
    --grape-700: #A54FBB;
    --grape-900: #9A54B5;
    --brightred: #cc2d23;

  }
 
  .dark {
    /* START: Defaults from shadcn */
    --background: 240 9.7% 6.1%;
    --foreground: 0 0% 98%;

    --card: 240 9.7% 6.1%;
    --card-foreground: 0 0% 95.7%;
 
    --popover: 240 9.7% 6.1%;
    --popover-foreground: 0 0% 95.7%;
 
    --primary: 287.6 70.1% 63.3%;
    --primary-foreground: 0 0% 98%;
 
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
 
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
 
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 95.7%;
 
    --destructive: 341.7 75.8% 35.7%;
    --destructive-foreground: 0 0% 98%;

    --border: 240, 3.7%, 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    /* END: Defaults from shadcn */
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}